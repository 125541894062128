import React from 'react'
import {Link} from 'gatsby'

const logo = () => {
  return (
    <div id="logo">
      <Link to="/">
        <svg width='228' viewBox='0 0 1200 218'>
          <path d='M360.923,130.67l-20.553,-62.161l-21.054,62.161l41.607,0Zm-38.882,-108.476l36.655,0l64.144,175.842l-41.607,0l-13.625,-39.38l-54.733,0l-13.369,39.38l-41.612,0l64.147,-175.842Z'
          fill='#3b3a3b' />
          <path d='M436.954,72.963l39.628,0l0,125.075l-39.628,0l0,-125.075Zm0,-50.768l39.628,0l0,35.912l-39.628,0l0,-35.912Z'
          fill='#3b3a3b' />
          <path d='M586.297,101.945c-9.581,0.167 -18.249,1.733 -26.008,4.704c-7.761,2.968 -13.371,7.433 -16.839,13.369l0,78.019l-39.629,0l0,-130.027l36.407,0l0,26.254c4.457,-8.751 10.192,-15.602 17.215,-20.554c7.013,-4.952 14.488,-7.512 22.413,-7.682l3.96,0c0.825,0 1.651,0.088 2.481,0.249l0,35.668Z'
          fill='#3b3a3b' />
          <path d='M723.009,57.858l-53.498,0l0,140.179l-40.615,0l0,-140.179l-53.743,0l0,-35.663l147.856,0l0,35.663Z'
          fill='#3b3a3b' />
          <path d='M794.834,101.945c-9.581,0.167 -18.249,1.733 -26.008,4.704c-7.761,2.968 -13.372,7.433 -16.84,13.369l0,78.019l-39.628,0l0,-130.027l36.407,0l0,26.254c4.457,-8.751 10.192,-15.602 17.215,-20.554c7.012,-4.952 14.488,-7.512 22.413,-7.682l3.96,0c0.825,0 1.65,0.088 2.481,0.249l0,35.668Z'
          fill='#3b3a3b' />
          <path d='M894.636,120.766c-0.825,-8.253 -3.799,-14.771 -8.912,-19.567c-5.124,-4.788 -11.48,-7.184 -19.075,-7.184c-7.761,0 -14.16,2.439 -19.188,7.309c-5.042,4.872 -7.973,11.356 -8.793,19.442l55.968,0Zm-27.489,79.746c-10.899,0 -20.678,-1.775 -29.347,-5.325c-8.668,-3.549 -16.017,-8.377 -22.043,-14.488c-6.031,-6.107 -10.61,-13.168 -13.744,-21.169c-3.14,-8.016 -4.709,-16.393 -4.709,-25.144c0,-9.411 1.569,-18.281 4.709,-26.621c3.134,-8.338 7.676,-15.639 13.62,-21.921c5.941,-6.27 13.25,-11.227 21.919,-14.859c8.668,-3.629 18.53,-5.446 29.595,-5.446c11.062,0 20.886,1.817 29.473,5.446c8.584,3.632 15.85,8.542 21.794,14.734c5.941,6.193 10.438,13.34 13.493,21.427c3.058,8.086 4.585,16.588 4.585,25.505c0,2.317 -0.082,4.584 -0.249,6.812c-0.167,2.232 -0.41,4.171 -0.743,5.822l-96.091,0c0.661,8.753 3.835,15.395 9.536,19.932c5.692,4.547 12.17,6.814 19.437,6.814c5.782,0 11.353,-1.399 16.718,-4.209c5.367,-2.806 8.954,-6.602 10.777,-11.393l33.681,9.412c-5.118,10.235 -13.043,18.57 -23.778,25.016c-10.734,6.436 -23.611,9.655 -38.633,9.655Z'
          fill='#3b3a3b' />
          <path d='M1042,198.036l-33.931,-52.256l-13.869,14.361l0,37.895l-39.628,0l0,-175.842l39.628,0l0,99.807l43.834,-53.992l42.105,0l-46.808,55.976l50.768,74.051l-42.099,0Z'
          fill='#3b3a3b' />
          <path d='M1146.75,200.512c-11.06,0 -21.961,-1.775 -32.69,-5.325c-10.734,-3.55 -19.81,-8.705 -27.243,-15.477l14.118,-24.273c7.925,5.452 15.644,9.661 23.153,12.634c7.516,2.97 14.742,4.454 21.676,4.454c5.113,0 9.078,-0.902 11.887,-2.719c2.802,-1.817 4.209,-4.465 4.209,-7.93c0,-3.468 -1.775,-6.23 -5.325,-8.296c-3.55,-2.066 -9.785,-4.246 -18.697,-6.56c-8.755,-2.481 -16.141,-4.87 -22.169,-7.185c-6.026,-2.309 -10.941,-4.867 -14.734,-7.681c-3.799,-2.802 -6.526,-6.021 -8.177,-9.655c-1.65,-3.632 -2.476,-7.926 -2.476,-12.883c0,-6.602 1.325,-12.628 3.968,-18.072c2.635,-5.449 6.269,-10.071 10.893,-13.869c4.621,-3.799 10.113,-6.769 16.469,-8.917c6.36,-2.148 13.253,-3.22 20.684,-3.22c9.737,0 18.858,1.32 27.365,3.963c8.502,2.64 16.718,7.428 24.645,14.361l-15.36,23.778c-7.426,-5.113 -14.034,-8.83 -19.811,-11.144c-5.782,-2.315 -11.393,-3.468 -16.839,-3.468c-4.296,0 -7.925,0.862 -10.899,2.595c-2.973,1.735 -4.46,4.505 -4.46,8.301c0,3.637 1.611,6.356 4.833,8.173c3.219,1.818 9.203,3.802 17.953,5.944c9.246,2.476 17.046,4.957 23.406,7.428c6.353,2.481 11.517,5.243 15.477,8.298c3.965,3.058 6.851,6.566 8.668,10.528c1.818,3.96 2.725,8.666 2.725,14.113c0,12.882 -4.791,23.116 -14.361,30.711c-9.584,7.6 -22.538,11.393 -38.888,11.393Z'
          fill='#3b3a3b' />
          <path d='M0,0.425l98.226,69.477l117.703,-69.477l-215.929,0Z' fill='#00a2d9'
          />
          <path d='M215.269,218l0,-216.18l-69.477,117.954l69.477,98.226Z' fill='#00a2d9'
          />
          <path d='M98.226,71.298l48.226,48.476l-139.862,94.631l91.636,-143.107Z'
          fill='#00a2d9' />
        </svg>
      </Link>
    </div>

  )
}

export default logo
