/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./Footer/Footer"
import styled, {ThemeProvider} from 'styled-components'
import {Theme, GlobalStyle} from '../themes/default'
import CookieConsent from "react-cookie-consent";

// import "./layout.css"

// TODO: import CookieConsent from "react-cookie-consent"; /* https://www.npmjs.com/package/react-cookie-consent */

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      

        <ThemeProvider theme = {Theme}>
        
          <>
          <GlobalStyle />
          <Header siteTitle={data.site.siteMetadata.title} />
          
        
          <main>{children}</main>
          <Footer />
          <CookieConsent>
            This website uses cookies to ensure you get the best experience on our website. <a target="_blank" href="http://cookiesandyou.com/">Learn More</a>
          </CookieConsent>
          </>

        </ThemeProvider>
      
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
