import React from 'react'
import {Link, graphql, StaticQuery} from 'gatsby'
import styled from 'styled-components'
import {minWidth} from '../../themes/default' // maxWidth
import MediaQuery from 'react-responsive';

const mainMenu = () =>
<MediaQuery query={minWidth.smallDesktop}>
  <Nav>
    
    <StaticQuery 
      query={data}
      render = { props => 
          <List>
          {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => {

            // https://www.w3schools.com/jsref/jsref_replace.asp -- replace "existing", "with"
            // const goodUrl = item.url.replace("https://content.travelkitties.com/", "")

            return (
              <li key={item.object_id}>
              { item.url.includes("https://content.flyairtreks.co.uk/") ? [
                <Link key={item.title} to={`/${item.object_slug}`}>
                  <span>{item.title}</span>
                </Link>
              ] : [<a key={item.title} href={item.url}><span>{item.title}</span></a>]
              }
              </li>
            )

          }
 
            
          )}
          </List>
         
      }
      
    />  
    
  </Nav>
</MediaQuery>

export default mainMenu;

const Nav = styled.nav`

  @media ${minWidth.smallDesktop} {
    display: block
  } 
`

const List = styled.ul`
  margin:0;
  padding:0;
  list-style: none;
  display: flex;

  a {
    color: ${props => props.theme.color.dark} !important;
    font-size:0.875rem;
    text-transform: uppercase;
    padding-left:20px;
    display: inline-block;
    text-decoration: none;

  }

  a:hover {
    color: ${props => props.theme.color.red} !important;
  }
`





const data = graphql`
{  
  allWordpressWpApiMenusMenusItems(filter : {name : {eq: "Main Menu"}}) {
    edges {
      node {
        name
        items {
          object_id
          title
          url
          object_slug
        }
      }
    }
  }
}
`