import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainMenu from "./mainMenu";
import Logo from './logo'
import styled from 'styled-components'
import {minWidth} from '../../themes/default' // maxWidth

const Header = ({ siteTitle }) => (
  <header>

    <h1 style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          position: "absolute",
          left: '-9999px',
          top: "-99999px"
        }}
      >
        {siteTitle}
      </Link>
    </h1>

    <HeaderHolder>      
      <Logo />
      <MainMenu />
    </HeaderHolder>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const HeaderHolder = styled.div`
  width: 100%;
  max-width: ${props=> props.theme.wide.desktopPlus};
  margin:0 auto;
  display: flex;
  padding:20px 1rem;
  box-sizing: border-box;

  #logo {
    margin: auto;
  }

  @media ${minWidth.smallDesktop} {
    justify-content: space-between;
    align-items: center;

    #logo {
      margin: 0
    }
  } 
  
`
