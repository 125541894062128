// https://noahgilmore.com/blog/easy-gatsby-image-components/
import React from "react"
import NetworkInformation from 'react-network-info';
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'



const SpeedImage = ( { src, alt } ) => ( // _withWebp_tracedSVG
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600,  quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(src);
      });
      if (!image) { return null; }
      
      const imageSizes = image.node.childImageSharp.fluid;
      return (
        
        <NetworkInformation>
        {props => 
          props.effectiveType !== '2g' ? (
        <Img
          alt={alt}
          fluid={imageSizes}
        />
          ) : (null)
        }
        </NetworkInformation>
      );
    }}
  />
)

export default SpeedImage