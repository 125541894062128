import React from 'react'
import NetworkInformation from 'react-network-info';
import styled from 'styled-components'

import SpeedImage from './speedImages'
import Img from 'gatsby-image'


const ImageBackground = (props) =>
  <Overlay>
    <Darker darkerby={props.darkerby}/> {/* 1 - 100 */ } 
    

    <NetworkInformation>
    { ({effectiveType, hasDB, src, fluid}) => 

      <>

      {/* <div>Source: {effectiveType}</div> */}
      

       {effectiveType === '2g' ? (
         <> </>
       ) : (
        <>
        {props.hasDB ? (
          <Img fluid={props.fluid} />
          ) : (
          <SpeedImage src={props.src}  />
          )
        } 
        </>
      
        
      )
      }
      </>
    
    
      }
    </NetworkInformation>


  </Overlay>

export default ImageBackground


const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(44,99,138,0.3);
    z-index: 1;
    left: 0;
    top:0;
    position: absolute;

    .gatsby-image-wrapper {
      height: 100%;
      width: auto;
    }
`

const Darker  = styled.div`
  background: rgba( 0,0,0, ${props =>(props.darkerby / 100 )} );
  position: absolute;
  z-index:2;
  top:0;
  left:0;
  width:100%;
  height: 100%;
`