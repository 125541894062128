import React from 'react'
import styled from 'styled-components'

const Footer = () => 

<FooterStyled>
  Airtreks.com is a registered trademark of AirTreks International, LLC, Copyright © © {new Date().getFullYear()} AirTreks International, LLC is owned and operated by Bootsnall Travel Network, LLC a Washington Corporation
</FooterStyled>

export default Footer

const FooterStyled = styled.footer`
  width:100%;
  max-width:600px;
  margin:0 auto;
  padding:50px 1rem;
  text-align: center;
  font-size: 0.825rem;
  box-sizing: border-box;
`