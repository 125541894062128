/**
 * Holder
 *
 * The component will pass all other props straight through to the form control, so you can add event handlers, type, id, etc.
 *
 * @function
 * @param {object} props Properties passed to function
 * @property {node} children 
 * @property {string} textAlign The name of the input field
 *
 * @todo TODO: allow caller to pass in class names that can be added to the .form-control class
 */

import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

/** Holder component description */
const Holder = ({ children, textAlign }) => {
  return (
    <HolderStyled
      textAlign = {textAlign}
    >
      {children}
    </HolderStyled>
  )
}

//props
Holder.defaultProps = {
  textAlign: ''
}

Holder.propTypes = {
  /** Some description here */
  children: PropTypes.node.isRequired,
  /** left, center, right */
  textAlign: PropTypes.string,
}





export default Holder





// styles
const HolderStyled = styled.div`
  overflow: hidden;
  position: relative;
  padding:2rem 1rem;
  text-align: ${props => props.textAlign};
  width:100%;
  box-sizing: border-box;
`
